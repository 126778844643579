@import url(https://db.onlinewebfonts.com/c/17241fb9c2ffb3ec11eba762e771435f?family=Suisse+Int%27l+Book);

:root {
  --dark-grey: #384853;
  --body-grey: #8b949d;
  --body-grey-lighter: #868a8ead;
  --accent: #0000ff;
}

body {
  font-family: "Suisse Int'l Boo", Helvetica, Arial, sans-serif;
  width: 100%;
}

h1 {
  font-size: 1.5rem;
}

.heading {
  font-size: 1.5rem;
  font-weight: bold;
}

.body {
  width: 95%;
  margin: 0vh auto;
  top: 0;
  left: 0;
  padding-top: 2vh;
}
a {
  color: black;
  text-decoration: none;
}
