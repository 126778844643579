.description {
  padding-top: 3vh;
  padding-bottom: 1vh;
  width: 100%;
  border-bottom: 1px solid black;
}

.experience-container {
  padding-right: 1vw;
}

.bio {
  font-size: 1.3rem;
  font-weight: 500;
}

.section-header {
  font-weight: bold;
  font-size: 1.3rem;
}

.projects-header {
  padding-bottom: 1vh;
  border-bottom: 1px solid black;
}

.pt-6 {
  padding-top: 6vh;
}

.project-links {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  justify-content: center;
  border-collapse: collapse; /* Add this line */
  padding: 1vh 0;
  border-bottom: 1px solid black;
  margin-bottom: 2vh;
}

.project-links p {
  padding-bottom: 2px;
  transition: all 0.25s ease;
}

.project-links p:hover,
.selected-project {
  cursor: pointer;
  color: var(--accent);
  font-weight: bolder;
}

.project-images {
  transition: all 0.25s ease;
  display: flex;
  width: 100%;
}

.project-images:hover {
  opacity: 0.7;
}

.project-images img {
  max-height: 400px;
  padding-right: 1vh;
  object-fit: cover;
  width: 100%;
}

.project-heading {
  font-size: 2.3rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  padding-top: 3vh;
}

.project-experience:hover {
  cursor: pointer;
}

.project-experience:hover {
  color: var(--accent);
}
.project-experience:hover a {
  color: var(--accent) !important;
}

.mobile-project-info {
  display: none;
  padding-top: 1.5vh;
}

@media screen and (max-width: 768px) {
  .mobile-project-info {
    display: flex;
    flex-direction: column;
  }
  .project-links,
  .projects-container {
    display: none;
  }
  .work .sidebar-container {
    border-top: none;
  }
  .project-images {
    width: 95%;
  }
  .project-experience:hover {
    color: black;
    cursor: auto;
  }
  .project-experience:hover a {
    color: black !important;
  }
}
