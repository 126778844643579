header {
  width: 100%;
  height: 50px;
  padding: 1.5vh 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  position: relative;
  top: 0;
  left: 0;
}
.titles {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.name {
  text-transform: uppercase;
  font-weight: bold;
  padding-right: 4vw;
  margin: 0;
}
.title {
  font-weight: bold;
  font-size: 1rem;
  margin: 0;
  color: var(--accent);
  text-transform: capitalize;
}
.name a {
  color: black;
}

header a {
  text-decoration: none;
}
.nav-container {
  display: flex;
}
.nav-container a {
  color: var(--body-grey);
  padding-right: 2vw;
  font-size: 1.2rem;
}

.nav-container a:hover,
.active {
  color: var(--accent);
}
