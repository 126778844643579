.experience-header {
  display: flex;
  justify-content: space-between;
}
p,
ul {
  margin: 0;
}
.experience-position {
  color: var(--body-grey);
}

.intro-container {
  padding-top: 3vh;
  overflow: auto;
  position: fixed;
  top: calc(5vh + 51px);
  left: calc(35% + 4vw);
  right: 3vw;
  bottom: 0;
}

.about-intro-container {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
}

.pic {
  width: 40%;
  object-fit: cover;
  padding-right: 2vw;
  object-position: 70%;
}

.contact {
  padding-top: 3vh;
}
.contact a {
  padding-right: 2vw;
}
.intro-container a,
.contact a {
  color: var(--body-grey);
  text-decoration: underline;
}
.heading a {
  color: black;
}
.info-section {
  display: flex;
  margin-bottom: 5vh;
  align-items: center;
}
.info-section img {
  height: 40vh;
  width: 40vh;
  object-fit: cover;
  object-position: 70%;
  padding-left: 2vw;
}

@media screen and (max-width: 768px) {
  .about {
    display: flex;
    overflow: auto;
    position: fixed;
    top: calc(5vh + 51px);
    bottom: 0;
    flex-direction: column;
  }
  .about .intro-container {
    order: 2;
  }
  .about .sidebar-container {
    order: 1;
  }
  .intro-container.about-intro-container {
    width: 90%;
    flex-direction: column;
  }
  .info-section {
    flex-direction: column;
  }
  .pic {
    width: 100%;
    padding-bottom: 5vh;
  }
}
